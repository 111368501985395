export default {
  classType: 'components',

  init() {
    let icon = this.el.querySelector('.input-password-show-icon');
    let field = this.el.querySelector('input[type=password]');

    // Show / Hide button may not be enabled.
    if (icon) {
      icon.addEventListener('click', () => {
        // Change icon.
        icon.querySelector('.icon').innerText =
          'visibility' + (field.type === 'text' ? '' : '_off');
        // Toggle field type.
        field.type = field.type === 'text' ? 'password' : 'text';
      });
    }
  },
};
